@use "./styles/mixins.scss" as *;
@use "./styles/variables.scss" as *;

body {
  font-family: $primary-font;
}

.projects,
.hero,
.contact-section {
  @include section;
}

.projects {
  padding: 0 16px;

  @include tablet {
    padding: 0 64px;
  }

  @include desktop {
    padding: 0 128px;
  }

  &__heading {
    font-size: 3rem;
    font-family: $primary-font;
    font-weight: $medium-weight;
    margin-top: 2rem;
    text-align: center;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 32px;

    @include tablet {
      gap: 16px;
    }
  }
}

.project {
  display: flex;
  gap: 16px;
  padding: 16px 0;
  flex-direction: column;
  width: 100%;

  @include tablet {
    flex-direction: row;
  }

  @include desktop {
    padding: 32px;
  }

  &__heading {
    font-size: 4rem;
    text-align: center;
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 16px;

    @include tablet {
      flex: 1;
    }
  }

  &__image {
    width: 100%;
    border: 1px solid black;

    // @include dark-mode {
    //   border-color: white;
    // }

    @include tablet {
      max-width: 300px;
    }
  }

  &__live-link {
    text-decoration: none;
    color: blue;
    display: block;
    margin-top: 8px;
    width: min-content;
    text-wrap: nowrap;

    // @include dark-mode {
    //   color: red;
    // }

    &:hover {
      color: red;

      // @include dark-mode {
      //   color: blue;
      // }
    }
  }

  &__github-link {
    color: blue;
    display: block;
    margin-top: 8px;
    text-decoration: none;
    width: min-content;
    text-wrap: nowrap;

    // @include dark-mode {
    //   color: red;
    // }

    &:hover {
      color: red;

      // @include dark-mode {
      //   color: blue;
      // }
    }
  }


  &__title {
    font-size: 1.5rem;
    margin: 0;
    font-weight: $light-weight;
  }

  &__description {
    max-width: 500px;
    font-size: 1rem;
    margin-top: 8px;
  }
}


@media screen and (max-width: 1100px) {
  .name {
    font-size: 7rem;
  }

}

@media screen and (max-width: 800px) {
  .name {
    font-size: 5rem;
  }

  .occupation {
    font-size: 1.5rem;
  }

}

@media screen and (max-width: 600px) {
  .about__copy {
    font-size: 1rem;
    line-height: 1.5;
  }

  .contact__heading,
  .projects__heading {
    font-size: 2.5rem;
    text-align: center;
  }

  .contact__link {
    font-size: 1rem;
  }

}

@media screen and (max-width: 374px) {
  .name {
    font-size: 2rem;
  }

  .occupation {
    font-size: 1rem;
  }

  .projects__heading,
  .contact__heading {
    font-size: 2rem;
  }

  .contact__link {
    font-size: 10px;
  }
}