* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: monospace, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
}


// @media (prefers-color-scheme: dark){
//   body{
//     background-color: black;
//     color: white;
//   }
// }