@mixin desktop {
    @media screen and (min-width: 1280px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: 768px) {
        @content;
    }
}

@mixin dark-mode {
    @media (prefers-color-scheme: dark){
        @content
    }
}

@mixin section {
    display: flex;
    min-height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 16px;
}