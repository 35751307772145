@use "../../styles/mixins.scss" as *;
@use "../../styles/variables.scss" as *;

.form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 2rem;
    border: 1px solid black;
    flex: 1;
    width: 100%;
    max-width: 700px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 2rem;
    border-radius: 4px;

    // @include dark-mode {
    //     border-color: white;
    // }

    &__heading {
        margin: 0;
        font-weight: 400;
    }

    &__name {
        width: 100%;
        padding: 8px;
        border-radius: 4px;
    }
    
    &__message {
        width: 100%;
        height: 5rem;
        resize: vertical;
        flex: 1;
        padding: 8px;
        min-height: 200px;
    }

    &__bottom-row {
        display: flex;
        flex-direction: column;
    }

    &__message-status {
        display: none;

        &--visible {
            display: inline-block;
            text-align: center;
            width: 100%;
        }

        &--successful {
            background-color: green;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            text-align: center;
            margin: 1rem auto;
            padding: 4px 8px;
            border-radius: 4px;
            font-weight: 600;
            color: white;
            border: 1px solid black;
        }

        &--failed {
            background-color: red;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            text-align: center;
            margin: 1rem auto;
            padding: 4px 8px;
            border-radius: 4px;
            font-weight: 600;
            color: white;
            border: 1px solid black;
        }
    }

    &__submit {
        width: 100%;
        background-color: blue;
        color: white;
        font-weight: 600;
        border: 1px solid black;
        padding: 8px 1rem;
        border-radius: 4px;

        // @include dark-mode {
        //     background-color: green;
        // }

        &:hover {
            background-color: darkblue;

            // @include dark-mode {
            //     background-color: darkgreen;
            // }
        }

        @include tablet {
            max-width: 150px;
            align-self: flex-end;
        }
    }
}