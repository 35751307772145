@use "../../styles/mixins.scss" as *;
@use "../../styles/variables.scss" as *;

$resume-text-color: #111;

.resume {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px;
    padding: 4px 8px;
    gap: 4px;

    &__text {
        font-size: 1rem;
        font-weight: 100;
        color: $resume-text-color;
        display: inline-block;
        font-family: $primary-font;
    }

    &__icon {
        color: $resume-text-color;
        font-size: 1.1rem;
        font-weight: 300;
    }

    &:hover {
        cursor: pointer;

        .resume__text, .resume__icon {
            color: blue;
        }
    }
}