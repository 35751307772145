@use "../../styles/variables.scss" as *;
@use "../../styles/mixins.scss" as *;

.contact {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    align-items: center;

    &__heading {
        font-size: 3rem;
        font-family: $primary-font;
        font-weight: $medium-weight;
        margin-top: 2rem;
        text-align: center;
    }

    &__link {
        text-decoration: none;
        color: black;

        // @include dark-mode {
        //     color: white;
        // }

    }

    &__items {

        @include desktop {
            display: flex;
            gap: 4rem;

        }
    }

    &__item {
        font-size: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        gap: 16px;

        &:hover {
            color: blue;
        }

    }

    &__icon {
        width: 30px;
        height: 30px;
    }

    &__handle {
        flex: 1;
        font-size: 1rem;
        // text-wrap: nowrap;
        font-weight: $medium-weight;


        @include tablet {
            font-size: 1.3rem;
        }
    }
}